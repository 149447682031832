import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'categoria/:idc',
    loadChildren: () => import('./categoria/categoria.module').then( m => m.CategoriaPageModule)
  },
  {
    path: 'piatto/:idp/:idc',
    loadChildren: () => import('./piatto/piatto.module').then( m => m.PiattoPageModule)
  },
  {
    path: 'carrello',
    loadChildren: () => import('./carrello/carrello.module').then( m => m.CarrelloPageModule)
  },
  {
    path: 'datiutente',
    loadChildren: () => import('./datiutente/datiutente.module').then( m => m.DatiutentePageModule)
  },
  {
    path: 'pagamento',
    loadChildren: () => import('./pagamento/pagamento.module').then( m => m.PagamentoPageModule)
  },
  {
    path: 'confermaordine/:ido',
    loadChildren: () => import('./confermaordine/confermaordine.module').then( m => m.ConfermaordinePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./registrazione/registrazione.module').then( m => m.RegistrazionePageModule)
  },
  {
    path: 'ristorante',
    loadChildren: () => import('./ristorante/ristorante.module').then( m => m.RistorantePageModule)
  },
  {
    path: 'storico',
    loadChildren: () => import('./storico/storico.module').then( m => m.StoricoPageModule)
  },
  {
    path: 'filtri',
    loadChildren: () => import('./filtri/filtri.module').then( m => m.FiltriPageModule)
  },
  {
    path: 'componibile/:idm',
    loadChildren: () => import('./componibile/componibile.module').then( m => m.ComponibilePageModule)
  },
  {
    path: 'componibilescelta/:idm/:nump',
    loadChildren: () => import('./componibilescelta/componibilescelta.module').then( m => m.ComponibilesceltaPageModule)
  },
  {
    path: 'confermadati',
    loadChildren: () => import('./confermadati/confermadati.module').then( m => m.ConfermadatiPageModule)
  },
  {
    path: 'riepilogoordine/:ido',
    loadChildren: () => import('./riepilogoordine/riepilogoordine.module').then( m => m.RiepilogoordinePageModule)
  },
  {
    path: 'termini',
    loadChildren: () => import('./termini/termini.module').then( m => m.TerminiPageModule)
  },
  {
    path: 'fornitori',
    loadChildren: () => import('./fornitori/fornitori.module').then( m => m.FornitoriPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
